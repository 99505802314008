<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12 sm12>
                <v-card style="overflow: hidden" class="primary--border" outlined>
                    <v-card-title class="title">
                        Routine
                        <v-spacer></v-spacer>

                    </v-card-title>
                    <v-card outlined>
                        <v-container grid-list-md>
                            <v-layout row wrap>

                                <v-flex xs6>
                                    <v-select :items="days" class="pa-0" label="Select Days" v-model="day"
                                              :disabled="!section" outlined dense/>
                                </v-flex>
                                <v-flex xs6>
                                    <v-select :items="subjects" class="pa-0" label="Select Subjects" v-model="subject"
                                              :disabled="!section"
                                              :loading="subjectLoading" outlined dense
                                    />
                                </v-flex>

                            </v-layout>
                        </v-container>

                    </v-card>
                    <template>

                        <v-data-table
                                :headers="headers"
                                :items="form.items.data"
                                :search="search"
                                :footer-props="footerProps"
                                :options.sync="pagination"
                                :server-items-length="form.items.meta.total">
                            <template v-slot:item="{index, item}">
                                <tr>
                                    <td class="text-xs-left">{{ item.start_time }} - {{ item.end_time }}</td>
                                    <td class="text-xs-left">{{ item.routine_type | capitalize }}</td>
                                    <td class="text-xs-left">{{ item.routine_day | capitalize }}</td>
                                    <td class="text-xs-left">{{ item.subject ? (item.subject.name ?
                                        item.subject.name:''):'' }}
                                    </td>
                                    <td class="text-xs-left">{{ item.period }}</td>
                                    <td class="text-xs-left">{{ item.shift | capitalize }}</td>
                                    <td class="text-xs-left">{{ item.teacher ? (item.teacher.name ?
                                        item.teacher.name :''):'' }}
                                    </td>
                                    <td class="text-xs-right">
                                        <edit-button permission="subject-routine-update"
                                                     @agree="updateRoutine(item)"/>
                                        <delete-button permission="subject-routine-delete"
                                                       @agree="form.delete(item.id)"/>
                                    </td>
                                </tr>
                            </template>

                        </v-data-table>
                    </template>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import {mapState} from 'vuex'
    import Form from '@/library/Form'
    import {filterObjectFromArray, getThForm} from "../../../../library/helpers";
    import Mixins from '@/library/Mixins';

    export default {
        mixins: [Mixins],
        data: () => ({
            valid: true,
            lazy: false,
            form: new Form({
                start_time: '',
                end_time: '',
                routine_day: '',
                routine_time: '',
                routine_id: '',
                section_id: '',
                grade_id: '',
                subject_id: '',
                routine_type: '',
                teacher_id: '',
                period: '',
                shift: '',
                day: ['sunday'],
            }, '/api/routine'),
            search: '',
            searchKey: null,
            update: false,
            periodCount: 0,
            filter: true,
            periodWiseData: [],
            pagination: {
                itemsPerPage: 100
            },
            headers: [
                {text: 'Time', align: 'left', value: 'start_time', width: 125, sortable: false},
                {text: 'Type', align: 'left', value: 'routine_type', sortable: false},
                {text: 'Day', align: 'left', value: 'routine_day', sortable: false},
                {text: 'Subject', align: 'left', value: 'subject', sortable: false},
                {text: 'Period', align: 'center', value: 'period', sortable: false},
                {text: 'Shift', align: 'left', value: 'shift', sortable: false},
                {text: 'Teacher', align: 'left', value: 'teacher', sortable: false},
                {text: 'Action', align: 'center', sortable: false}
            ],

            day: 'all',
            sections: [],
            editDisable: false,
            grades: [],
            gradeLoading: false,
            grade: '',
            section: '',
            subject: '',
            subjects: [],
            teachersList: [],
            subjectLoading: false,
            teachers: [],
            subjectRoutines: [],
            days: [
                {text: 'All', value: 'all'},
                {text: 'Sunday', value: 'sunday'},
                {text: 'Monday', value: 'monday'},
                {text: 'Tuesday', value: 'tuesday'},
                {text: 'Wednesday', value: 'wednesday'},
                {text: 'Thursday', value: 'thursday'},
                {text: 'Friday', value: 'friday'},
                {text: 'Saturday', value: 'saturday'},
            ],
            baar: ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'],
            shifts: [
                {text: 'Morning', value: 'morning'},
                {text: 'Day', value: 'day'},
                {text: 'Evening', value: 'evening'},
            ],
            routineType: [
                {text: 'Theory', value: 'theory'},
                {text: 'Practical', value: 'practical'},
                {text: 'Leisure', value: 'leisure'},
            ],
            startTimeDialog: false,
            endTimeDialog: false,
            isLoading: false,
            entries: [],
            printUrl: ''
        }),

        computed: {
            ...mapState(['batch']),

        },

        mounted() {
            const student = JSON.parse(this.$storage.get('_s_ch'));
            this.grade = student.grade_id;
            this.section = student.section_id;
        },

        watch: {
            'pagination': function () {
                this.get();
            },

            'grade': function () {
                this.form.items.data = [];
                this.getSubjects();
            },
            'section': function () {
                this.get();

            },
            'day': function () {
                this.get();
            },
            'subject': function () {
                this.getSubjectRoutines();
                this.editDisable = false;
            },

        },

        methods: {
            filterObjectFromArray, getThForm,

            get(params) {
                if (this.section && this.day) {
                    let extraParams = 'sectionId=' + this.section + '&day=' + this.day;
                    this.form.get(null, this.queryString(extraParams)).then(({data}) => {
                        let periodCollection = [];
                        data.data.map(res => {
                            periodCollection.push(parseInt(res.period))
                        });
                        if (periodCollection.length > 0) {
                            this.periodCount = periodCollection.reduce(function (a, b) {
                                return Math.max(a, b);
                            });
                            let i = 1;
                            for (i = 1; i <= this.periodCount; i++) {
                                this.periodWiseData[i] = data.data.filter(res => {
                                    if (res.period === i) return res;
                                });
                            }
                        }

                    })
                }
            },


            getSubjects() {
                this.subjectLoading = true;

                this.$rest.get('/api/subject?rowsPerPage=25&grade=' + this.grade + '&batch=' + this.batch.id).then(({data}) => {
                    this.subjects = [];
                    this.subjects.push({value: '', text: 'All Subjects'})
                    data.data.map(item => {
                        this.subjects.push({value: item.id, text: item.name});
                    });
                }).finally(() => {
                    this.subjectLoading = false;
                })
            },

            getSubjectRoutines() {
                if (this.subject) {
                    this.subjectRoutines = [];
                    this.$rest.get('/api/routine-subject-wise?sectionId=' + this.section + '&subjectId=' + this.subject).then(res => {
                        this.subjectRoutines = res.data.data;
                        this.form.items.data = res.data.data;
                    })
                }
            },
        }
    }
</script>
<style lang="scss" scoped>
    .inputTime {
        border: solid 1px #777;
        width: 65px;
        text-align: center;
        height: 32px;
    }

    .teacherInfo {
        position: absolute;
        margin-top: -21px;
        font-size: 12px;
        color: #777;
    }

    .inputTime:disabled {
        cursor: not-allowed;
        background: #99999921;
    }

    .theme--light.v-table tbody td {
        border: 1px solid rgba(0, 0, 0, .22);
    }

    .cellHover {
        cursor: pointer;
    }

    table td div p {
        padding-bottom: 0px;
        margin-bottom: 7px;
    }

    table td div {
        margin-top: 5px;
    }

    table td div:nth-child(2n+1) {
        border-bottom: 1px solid #ccc;
    }

    table td div:last-child {
        border-bottom: none;
    }
</style>